import React, { Component } from 'react'
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';

export class BookingSingle extends Component {

    constructor(props){
        super(props);


        this.state = {
            showConfirm: false,
            eventInfo: props.info,
            canCancel: true,
            mobileValue: '', 
            numberSize: '12px', 
            openPrefix: false, 
            prefixValue: '39',
            prefixDisplay: '🇮🇹 +39',
            openConfirm: false,
            isProcessing: false,
            comments: ''
        };
       
    }


    cancel = (e) => {
        this.props.cancel();
    }


    toggle = () => {
        this.setState({ openPrefix: false, openConfirm: false })
    }
       
    showPrefixSelection = (e) => {
        this.setState({ openPrefix: true});
    }

    selectPrefix = (e) => {
        
            this.setState({ prefixDisplay: e.target.innerHTML, prefixValue: e.target.id});
            this.toggle();
        
    }

    insertNumber = (e) => {

        var insert = String(e.target.value);
        
        if(!isNaN(insert)){
            this.setState({ mobileValue: insert});
        }

        if(e.target.value.length>0){
            this.setState({ numberSize: '14px'});
        }else{
            this.setState({ numberSize: '12px'});
        }

        if(e.target.value.length>7 && this.state.comments.length > 0){
            this.setState({ showConfirm: true})
        }else{
            this.setState({ showConfirm: false})
        }

    }

    insertComment = (e) => {

        var insert = String(e.target.value);
        
        this.setState({ comments: insert });

        if(e.target.value.length>0 && this.state.mobileValue.length > 7){
            this.setState({ showConfirm: true})
        }else{
            this.setState({ showConfirm: false})
        }

    }

    confirmBook = (e) => {
        
        if(this.state.mobileValue != ''){
          this.setState({ openConfirm: true });
        }
    }

    sendBooking = () => {
       this.props.bookNow(this.state.prefixValue, this.state.mobileValue, this.state.comments);
    }

    render() {
        return (
            <div>
                <br />

                 <div style={{marginTop: '40px'}}>
            <p style={{color: 'white', fontSize: '13px'}}>inserisci il numero di telefono</p>
            <p style={{marginTop: '-12px', color: 'white', fontSize: '13px'}}>a cui inviare la prenotazione</p>
            </div>



            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'rgb(30,30,30)'}}>
            <div style={{ cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', marginLeft: '0px',
             fontSize: '14px', fontWeight: 'thin'}} onClick={this.showPrefixSelection}>
            {this.state.prefixDisplay}
            </div>
            <div>
                    <input 
                style={{ paddingLeft: '30px', border: 'none', color: 'white', fontWeight: 'bold', height: '40px', width: '150px', borderRadius: '8px', 
                backgroundColor: 'rgb(30,30,30)', outlineWidth: '0', fontSize: this.state.numberSize}} type="tel"  
                name="mobile"
                value={this.state.mobileValue}
                placeholder="numero di telefono"
                onChange={this.insertNumber}
                />
                </div>
                </div>
                </div>


                <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'rgb(30,30,30)'}}>
                <input 
                style={{ paddingLeft: '20px', border: 'none', color: 'white', fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(30,30,30)', outlineWidth: '0', fontSize: this.state.numberSize}} type="text"  
                name="invitation comments"
                value={this.state.comments}
                placeholder="nome e cognome"
                onChange={this.insertComment}
                />
                
                </div>
                </div>


                { this.state.showConfirm && 
                <div  style={{marginTop: '15px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(255,194,0)', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmBook}
            >
                    Avanti</p>
                    </div>
                }


                 {
                        this.state.canCancel
                   ? (
                    <div onClick={this.cancel} style={{ marginTop: '50px', cursor: 'pointer', fontSize: '14px', color: 'white' }}>annulla</div>
                   )
                   : (
                     null
                   )
                    }

                    <br />
                    <br />
                    <br />


                    <Drawer
                    open={this.state.openConfirm}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>Stai inviando una prenotazione al numero 
                    <p style={{ color: 'rgb(30,30,30)', fontWeight: 'bold', fontSize: '16px'}}>+{this.state.prefixValue} {this.state.mobileValue}</p></div>
                    </div>
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(255,194,0)', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.sendBooking}
            >Invia</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>annulla</div>
                    </div>
                    </div>
                    </Drawer>
                    
                    <Drawer
                    open={this.state.openPrefix}
                        onRequestClose={this.toggle}
                        modalElementClass="modalPrefix"
                        style={{ background: 'red'}}>
                            
                            <div style={{marginLeft: '-10px'}}>
                            <div  style={{ marginTop: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="39">🇮🇹 +39</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="41">🇨🇭 +41</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="49">🇩🇪 +49</p>
                    
                    </div>
                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="43">🇦🇹 +43</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="44">🇬🇧 +44</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="33">🇫🇷 +33</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="31">🇳🇱 +31</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="81">🇯🇵 +81</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="86">🇨🇳 +86</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="30">🇬🇷 +30</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="7">🇷🇺 +7</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="34">🇪🇸 +34</p>

                    </div>


                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="32">🇧🇪 +32</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="47">🇳🇴 +47</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="46">🇸🇪 +46</p>

</div>

                    </div>
                    </Drawer>

            </div>
        )
    }
}

export default BookingSingle
