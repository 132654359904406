import React, { Component } from 'react'

export class EventList extends Component {

    constructor(props){
        super(props);
        this.state = { events: props.events };
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.events !== props.events){
            return {
                events: props.events          
               }
        }        
        return null;
    }

    selectEvent = (index) => {
        this.props.select(index);
    }

    render() {
        return (
            <div>
                <div style={{ marginTop: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>   
                <div>  
                {
                    this.state.events.map((event, i) =>

                    <div  onClick={() => this.selectEvent(i)} key={i} style={{marginTop: '15px', width: '250px', backgroundColor: 'rgb(30,30,30)', borderRadius: '5px', color: 'rgb(230,230,230)', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
                 >
                     <div style={{ marginLeft: '15px', width: '30px'}}>
                       <p style={{fontSize: '20px'}}>🥳</p>
                        </div>
                        <div style={{ width: '200px', textAlign: 'left', 
                    justifyContent: 'left', alignItems: 'left', marginLeft: '15px'}}>
                        <p style={{color: 'white', fontWeight: 'bold'}}>{event.name}</p>
                        <p style={{marginTop: '-10px'}}> {event.booking_type} </p>
                        <p style={{marginTop: '-10px'}}> {event.date_start} </p>
                        </div>                                    
                        </div>
                   
                
                )

            }

            </div>
            </div>

            { this.state.events.length === 0 && <div style={{ marginTop: '40px', color: 'rgb(210,210,210)', fontSize: '14px', fontWeight: 'normal'}}>Non ci sono eventi</div>}

            </div>
        )
    }
}

export default EventList
