import React, { Component } from 'react'
import PlaceList from './PlaceList';
import PlaceInfo from './PlaceInfo';

export class PlaceManager extends Component {

    constructor(props){
        super(props);
        this.state = { info: props.info, showIndex: null };
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.info !== props.info){
            return {
                info: props.info          
               }
        }        
        return null;
    }

    goBack = () => {
        this.setState({ showIndex: null });
    }

    showPlace = (index) => {
        this.setState({ showIndex: index });
    }

    render() {
        return (
            <div>
                {this.state.showIndex === null && 
                <PlaceList select={this.showPlace} places={this.state.info} />
                }
                {this.state.showIndex !== null && 
                <PlaceInfo totPlaces={this.state.info.length} goBack={this.goBack} info={this.state.info[this.state.showIndex]} />
                }
            </div>
        )
    }
}

export default PlaceManager
