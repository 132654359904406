import React, { Component } from 'react'
import EventManager from './EventManager';
import backArrow from './arrow_back.svg';
import openIcon from './open.svg';

export class PlaceInfo extends Component {

    constructor(props){
        super(props);
        var canGoBack = false;
        if(props.totPlaces > 0){
            canGoBack = true;
        }
  
        this.state = { info: props.info, canGoBack: canGoBack };
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.info !== props.info){
            var canGoBack = false;
            if(props.totPlaces > 0){
                canGoBack = true;
            }
            return {
                info: props.info,
                canGoBack: canGoBack         
               }
        }        
        return null;
    }

    goBack = () => {
        this.props.goBack();
    }

    openGeneralUrl = () => {
        window.open(this.state.info.general_url, "_blank");
    }

    render() {
        return (
            <div>
                { this.state.canGoBack && 
                <div onClick={this.goBack}  style={{ marginTop: '-90px', position: 'absolute', display: 'flex', justifyContent: 'left', alignItems: 'center' }}> 
                <img onClick={this.goBack} alt="back" style={{ cursor: 'pointer', marginLeft: '10px', color: 'black', height: '25px'}} src={backArrow} /> 
                <p onClick={this.goBack} style={{ cursor: 'pointer', fontSize: '14px', color: 'rgb(250,250,250)'}}>locali</p>
                </div>
                }
            <div style={{ marginTop: '-10px', color: 'white', fontSize: '22px', fontWeight: 'normal'}}>{this.state.info.name}</div>
            <div style={{ marginTop: '0px', color: 'rgb(230,230,230)', fontSize: '16px', fontWeight: 'normal'}}>{this.state.info.prname}</div>
            
            <div onClick={this.openGeneralUrl}  style={{ marginTop: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
                
                <div style={{ marginTop: '5px', color: 'rgb(240,240,240)', fontSize: '12px', fontWeight: 'normal'}}>Il tuo link generale</div>
                <img onClick={this.openGeneralUrl} alt="back" style={{ cursor: 'pointer', marginTop: '5px', marginLeft: '15px', color: 'black', height: '15px'}} src={openIcon} /> 

            </div>
            <br />
            <EventManager prinfo={this.state.info} events={this.state.info.next_events} />
           


            </div>
        )
    }
}

export default PlaceInfo
