import React, { Component } from 'react'
import backArrow from './arrow_back.svg';
import BookingInfo from './BookingInfo';
import NewBooking from './NewBooking';
import openIcon from './open.svg';

export class EventInfo extends Component {

    constructor(props){
        super(props);
        
        this.state = { info: props.info, prinfo: props.prinfo, bookings: [], singleBookings: 0, totalGuests: 0, showBookings: true, canBooking: false };
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.info !== props.info || current_state.prinfo !== props.prinfo){
            
            return {
                info: props.info,
                prinfo: props.prinfo     
               }
        }        
        return null;
    }

    componentDidMount () {

        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
            this.window.reload();
        }else{
            this.getEventInfo();
        }
       
    }

    getEventInfo = () => {

        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
          sessiontoken = '';
        }
    
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
                    token: sessiontoken,
                    id: this.state.info.id,
                    pr: this.state.prinfo.prid
                  })
          };
        
              const apiUrl = 'https://getfy-business.com/pr/v1/event/info';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => { 
            this.setState({ bookings: r.info, canBooking: r.can_booking });          
        }).catch(e => {
        });

    }

    goBack = () => {
        this.props.goBack();
    }


    showBookings = (show) => {
        this.getEventInfo();
        this.setState({ showBookings: show });
    }

    openGeneralUrl = () => {
        window.open(this.state.info.event_url, "_blank");
    }

    render() {
        return (
            <div>
                
                <div onClick={this.goBack}  style={{ marginTop: '-170px', marginLeft: '0px', position: 'absolute', display: 'flex', justifyContent: 'left', alignItems: 'center' }}> 
                <img onClick={this.goBack} alt="back" style={{ cursor: 'pointer', marginLeft: '10px', height: '25px'}} src={backArrow} /> 
                <p onClick={this.goBack} style={{ cursor: 'pointer', fontSize: '14px', color: 'rgb(250,250,250)'}}>eventi</p>
                </div>
                

                <div style={{ marginTop: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>   
                <div>  
                <div  style={{marginTop: '15px', width: '250px', backgroundColor: 'rgb(30,30,30)', borderRadius: '5px', color: 'rgb(230,230,230)', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
                 >
                     <div style={{ marginLeft: '15px', width: '30px'}}>
                       <p style={{fontSize: '20px'}}>🥳</p>
                        </div>
                        <div style={{ width: '200px', textAlign: 'left', 
                    justifyContent: 'left', alignItems: 'left', marginLeft: '15px'}}>
                        <p style={{color: 'white', fontWeight: 'bold'}}>{this.state.info.name}</p>
                        <p style={{marginTop: '-10px'}}> {this.state.info.booking_type} </p>
                        <p style={{marginTop: '-10px'}}> {this.state.info.date_start} </p>
                        </div>                                    
                        </div>
                        </div>                                    
                        </div>

            <div onClick={this.openGeneralUrl}  style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
                
                <div style={{ marginTop: '5px', color: 'rgb(240,240,240)', fontSize: '12px', fontWeight: 'normal'}}>Il tuo link evento</div>
                <img onClick={this.openGeneralUrl} alt="back" style={{ cursor: 'pointer', marginTop: '5px', marginLeft: '15px', color: 'black', height: '15px'}} src={openIcon} /> 

            </div>

            { this.state.canBooking &&
             <NewBooking showBookings={this.showBookings} prinfo={this.state.prinfo} event={this.state.info} />
            }
                   
            { this.state.showBookings &&
            <BookingInfo info={this.state.info} bookings={this.state.bookings} />
            }



            </div>
        )
    }
}

export default EventInfo
