import React, { Component } from 'react'
import i18n from "i18next";
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';



export class UserProfile extends Component {

    constructor(props){
        super(props);
        
        var generalInfo = props.info;

        this.state = { email: props.info.email, info: generalInfo, nameValue: generalInfo.name, surnameValue: generalInfo.surname, isExpanded: false, showConfirm: false, isProcessing: false, changeImage: false };
        this.props.open(false);


    }


    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    insertData = (e) => {
        var insert = e.target.value;
        if(e.target.name != 'email'){
            insert =  this.capitalizeFirstLetter(insert);
        }   
        this.setState({ [e.target.name] : insert});

        if(e.target.name == 'nameValue'){
            this.checkInfoChanged(insert, this.state.surnameValue);
        }else{
            this.checkInfoChanged( this.state.nameValue, insert);
        }
        
    }

    expandUserProfile = () => {
        this.setState({ isExpanded: true });
        this.props.open(true);
    }

    hideUserProfile = () => {
        this.setState({ isExpanded: false, nameValue: this.state.info.name, surnameValue: this.state.info.surname });
        this.props.open(false);
    }

    checkInfoChanged = (name, surname) => {
        if((this.state.info.name!=name || this.state.info.surname!=surname) && name!="" && surname!=""){
            this.setState({ showConfirm: true });
        }else{
            this.setState({ showConfirm: false });
        }
    }

    regenrateProfilePicture = () => {
        
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true});
           

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken
                  })
                };

                
                const apiUrl = 'https://getfy-business.com/pr/v1/user/regenerate-image';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
               
                window.location.reload();
         
              }).catch(e => {
                window.location.reload();
              });
        }
    }

    confirmChange = () => {
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true});
           

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify({
                    token: sessiontoken,
                    name: this.state.nameValue,
                    surname: this.state.surnameValue,
                    email: this.state.email
                  })
                };

                
                const apiUrl = 'https://getfy-business.com/pr/v1/user/set/name';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
               
                window.location.reload();
         
              }).catch(e => {
                window.location.reload();
              });
        }
    }


    changeImage = () => {
        this.setState({ changeImage: true });
    }

    toggle = () => {
        this.setState({ changeImage: false });
    }

    onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        console.log(file);
        this.sendUserImage(file);
    }

    sendUserImage = (image) => {
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true});
           

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
                sessiontoken = '';
            }

            var data = new FormData();
            data.append('file', image);
            data.append('token', sessiontoken);

            const requestOptions = {
                method: 'POST',
                body: data
                };

                
                const apiUrl = 'https://getfy-business.com/pr/v1/user/upload-image';
          
              fetch(apiUrl, requestOptions)
              .then(r => r.json())
              .then(r => {
               
                window.location.reload();
         
              }).catch(e => {
                window.location.reload();
              });
        }
    }

    uploadImage = () => {
        this.refs.fileUploader.click();
    }

  render() {

  

    return (
      <div>

        { !this.state.isExpanded &&
        <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
        <img onClick={this.expandUserProfile} style={{ width: '35px', height: '35px', borderRadius: '50%', cursor: 'pointer' }} src={this.state.info.user_photo} />       
        </div>
        }


        { this.state.isExpanded &&
        <div style={{ marginTop: '50px' }}>
        <img style={{ width: '60px', height: '60px', borderRadius: '50%', cursor: 'pointer' }} src={this.state.info.user_photo} />   
        <div onClick={this.changeImage} style={{ cursor: 'pointer', marginTop: '5px', color: '#FFFFFFD9', fontSize: '10px', fontWeight: 'bold'}}>{i18n.t('change.image')}</div>


        <div style={{marginTop: '30px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
                <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: '#1E1E1E' }}>
                <input 
                style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: '#FFFFFF', height: '43px', width: '250px',
                 outlineWidth: '0',backgroundColor: '#1E1E1E', fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
                name="nameValue"
                value={this.state.nameValue}
                placeholder={i18n.t('name')}
                onChange={this.insertData}
                />
                </div>
                
            </div>

            <div style={{marginTop: '10px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
                <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: '#1E1E1E'}}>
                <input 
                style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: '#FFFFFF', height: '43px', width: '250px',
                 outlineWidth: '0',backgroundColor: '#1E1E1E', fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text" 
                name="surnameValue"
                value={this.state.surnameValue}
                placeholder={i18n.t('surname')}
                onChange={this.insertData}
                />
                </div>
                
            </div>

            <div style={{marginTop: '15px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
             <div style={{ height: '45px', borderRadius: '8px', justifyContent: 'center', alignItems: 'center'}}>
             <input 
             style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: '#FFFFFF', height: '45px', width: '250px',
              outlineWidth: '0',backgroundColor: '#1E1E1E', fontSize: '14px', textAlign: 'center'}} type="email" 
             name="email"
             value={this.state.email}
             placeholder={i18n.t('Email')}
             onChange={this.insertData}
             />
             </div>
             
       
             
         </div>

            {this.state.showConfirm &&
            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#FFC200', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF' }}
            onClick={this.confirmChange}
            >
                    {i18n.t('confirm')}</p>
            </div>
            }
            <br />
            <div onClick={this.hideUserProfile} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(240,240,240)'}}>{i18n.t('back')}</div>
            
            <br />
            <br />

            <input type="file" id="file" accept="image/png, image/jpeg" ref="fileUploader" 
   onChange={this.onChangeFile.bind(this)} style={{display: "none"}}/>

        </div>
        }

                <Drawer
                    open={this.state.changeImage}
                        onRequestClose={this.toggle}
                        modalElementClass="modalPrefix">
                            <div>
                    
                            <div  style={{marginTop: '20px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#FFC200', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF'}}
            onClick={this.regenrateProfilePicture}
            >{i18n.t('reset.image')}</p>
                    </div>

                    <div  style={{marginTop: '-5px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#FFC200', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF'}}
            onClick={this.uploadImage}
            >{i18n.t('upload.image')}</p>
                    </div>

                    <div  style={{ marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>{i18n.t('back')}</div>
                    </div>
                    </div>
                    </Drawer>


      </div>
    )
  }
}

export default UserProfile