import React, { Component } from 'react'

export class BookingDetailsTable extends Component {

    constructor(props){
        super(props);
        var singleBooking = props.bookings.length;
        var totalGuests = 0;
        props.bookings.forEach(booking => {
            totalGuests += parseInt(booking.BookGuest);
        });
        this.state = { info: props.info, bookings: props.bookings, singleBookings: singleBooking, totalGuests: totalGuests };
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.bookings !== props.bookings || current_state.info !== props.info ){
            var singleBooking = props.bookings.length;
            var totalGuests = 0;
            props.bookings.forEach(booking => {
                totalGuests += parseInt(booking.BookGuest);
            });
            return {
                info: props.info,
                bookings: props.bookings,
                singleBookings: singleBooking,
                totalGuests: totalGuests     
               }
        }        
        return null;
    }



    render() {
        return (
            <div>
                 <div style={{ marginTop: '30px', color: 'rgb(230,230,230)', fontSize: '14px', fontWeight: 'normal'}}>Prenotazioni uniche: {this.state.singleBookings}</div>
                    <div style={{ marginTop: '5px', color: 'rgb(230,230,230)', fontSize: '14px', fontWeight: 'normal'}}>Ospiti totali: {this.state.totalGuests}</div>

                        <div style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>   
                <div>  
                {
                    this.state.bookings.map((booking, i) =>
                    
                    <div key={i} style={{marginTop: '15px', width: '250px', backgroundColor: 'rgb(30,30,30)', borderRadius: '5px', color: 'rgb(230,230,230)', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
                 >
                     <div style={{ marginLeft: '0px', width: '30px'}}>
                       <p style={{fontSize: '20px'}}>🗓</p>
                        </div>
                        <div style={{ marginTop: '0px', width: '200px', textAlign: 'left', 
                    justifyContent: 'left', alignItems: 'left', marginLeft: '15px'}}>
                        <p style={{color: 'white', fontWeight: 'bold'}}>{booking.Name}</p>
                        <p style={{marginTop: '-10px', color: 'rgb(240,240,240)'}}> {booking.BookGuest} {
                   booking.BookGuest === '1'
                   ? (
                    " ospite"
                   )
                   : (
                    " ospiti"
                   )
                } alle {booking.BookHour}:{booking.BookMinute} </p>
                        <p style={{marginTop: '-10px', fontSize: '10px', marginRight: '15px'}}>{booking.BookComments} </p>
                        </div> 
                        {
                   booking.Accepted === '1'
                   ? (
                    <div style={{ height: '10px', marginLeft: '-25px', backgroundColor: 'green', width: '10px', borderRadius: '5px', textAlign: 'left', 
                    justifyContent: 'left', alignItems: 'left'}}></div>
                   )
                   : (
                    <div style={{ height: '10px', marginLeft: '-25px', backgroundColor: 'rgb(255,194,0)', width: '10px', borderRadius: '5px', textAlign: 'left', 
                    justifyContent: 'left', alignItems: 'left'}}></div>
                   )
                } 
                       

                        </div>
                   
                
                )

            }

            </div>
            </div>
            </div>
        )
    }
}

export default BookingDetailsTable
