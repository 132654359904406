import React, { Component } from 'react'
import BookingSingle from './BookingSingle';
import BookingTable from './BookingTable';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';

export class NewBooking extends Component {

    constructor(props){
        super(props);
        
        this.state = { prinfo: props.prinfo, event: props.event, showBookForm: false, showBookSingleForm: false, isProcessing: false, openError: false };
    }

    static getDerivedStateFromProps(props, current_state) {
        if( current_state.prinfo !== props.prinfo || current_state.event !== props.event ){

            return {
                prinfo: props.prinfo,
                event: props.event 
               }
        }        
        return null;
    }


    createBooking = () => {
        this.props.showBookings(false);
        if(this.state.event.booking_type === 'Tavoli'){
            this.setState({ showBookForm: true });
        }else{
            this.setState({ showBookSingleForm: true });
        }
        
    }

    cancelBooking = () => {
        this.props.showBookings(true);
        this.setState({ showBookForm: false, showBookSingleForm: false });
    }

    toggle = () => {
        this.props.showBookings(true);
        this.setState({ prefixValue: '', mobileValue: '', openError: false, showBookForm: false, showBookSingleForm: false });
    }

    bookNow = (prefix, mobile, guests, hour, comments, options) => {
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true });

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
              sessiontoken = '';
            }
        
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: JSON.stringify({
                        token: sessiontoken,
                        event: this.state.event.id,
                        phone:  mobile,
                        prefix: prefix,
                        pr: this.state.prinfo.prid,
                        guests: guests,
                        hour: hour,
                        comments: comments,
                        options: options
                      })
              };
            
                  const apiUrl = 'https://getfy-business.com/pr/v1/booking/table';
        
            fetch(apiUrl, requestOptions)
            .then(r => r.json())
            .then(r => { 
                if(r.status == 'ok'){
                    this.props.showBookings(true);
                    this.setState({ showBookForm: false, showBookSingleForm: false, isProcessing: false });
                }else{
                    this.setState({ prefixValue: prefix, mobileValue: mobile, openError: true, showBookForm: false, showBookSingleForm: false, isProcessing: false });
                }        
            }).catch(e => {
                window.location.reload();
            });

        }
    }

    bookNowSingle = (prefix, mobile, comments) => {
        if(!this.state.isProcessing){
            this.setState({ isProcessing: true });

            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
              sessiontoken = '';
            }
        
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: JSON.stringify({
                        token: sessiontoken,
                        event: this.state.event.id,
                        phone:  mobile,
                        prefix: prefix,
                        pr: this.state.prinfo.prid,
                        comments: comments
                      })
              };
            
                  const apiUrl = 'https://getfy-business.com/pr/v1/booking/single';
        
            fetch(apiUrl, requestOptions)
            .then(r => r.json())
            .then(r => { 
                if(r.status == 'ok'){
                    this.props.showBookings(true);
                    this.setState({ showBookForm: false, showBookSingleForm: false, isProcessing: false });
                }else{
                    this.setState({ prefixValue: prefix, mobileValue: mobile, openError: true, showBookForm: false, showBookSingleForm: false, isProcessing: false });
                }
                    
                         
            }).catch(e => {
                window.location.reload();
            });

        }
    }

    render() {
        return (
            <div>
                
                {( this.state.prinfo.can_add==='1' && (!this.state.showBookForm && !this.state.showBookSingleForm)) && 
                        <div  style={{marginTop: '25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(255,194,0)', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.createBooking}
            >
                    Nuova prenotazione</p>
                    </div>
                    }

{ this.state.showBookForm && <BookingTable maxGuests={this.state.event.max_guests} cancel={this.cancelBooking} info={this.state.event} bookNow={this.bookNow} /> }
{ this.state.showBookSingleForm && <BookingSingle cancel={this.cancelBooking} info={this.state.event} bookNow={this.bookNowSingle} /> }


                <Drawer
                    open={this.state.openError}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>Non è stato possibile inviare la prenotazione al numero 
                    <p style={{ color: 'rgb(30,30,30)', fontWeight: 'bold', fontSize: '16px'}}>+{this.state.prefixValue} {this.state.mobileValue}</p>
                    
                    La prenotazione potrebbe essere in fase di accettazione o già esistente</div>
                    </div>
                    
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{marginTop: '20px', fontWeight: 'bold', cursor: 'pointer', fontSize: '16px', color: 'rgb(60,60,60)'}}>chiudi</div>
                    </div>
                    </div>
                    </Drawer>

            </div>
        )
    }
}

export default NewBooking
