import React, { Component } from 'react'
import ThemeManager from '../ThemeManager';
import i18n from "i18next";
import { isValid } from 'date-fns';

export class Email extends Component {

    constructor(props){
        super(props);
        let themeManager = ThemeManager.getInstance();
        var _itemBackgroundColor = themeManager.getItemBackgroundColor();
        var _mainTextColor = themeManager.getMainTextColor();
        
        this.state = { userInfo: props.user, email: '', itemsColor: [_itemBackgroundColor, _itemBackgroundColor, _itemBackgroundColor], itemsTextColor: [_mainTextColor, _mainTextColor, _mainTextColor], selected: 0};
       
    }

     isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

    confirm = (e) => {
        console.log(this.state.email);
        if(this.state.email.length<4){
            console.log("ah11xs");
            return;
        }
        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
          sessiontoken = '';
        }
    
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
                    email: this.state.email,
                    token: sessiontoken
                  })
          };
        
              const apiUrl = 'https://getfy-business.com/pr/v1/user/set/email';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => { 
            window.location.reload();
        }).catch(e => {
            window.location.reload();
        });
    }

    insertData = (e) => {
        var insert = e.target.value;
        this.setState({ [e.target.name] : insert});
    }

    render() {


        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                
             <div style={{marginTop: '80px'}}>
            <p style={{color: _mainTextColor, fontSize: '13px'}}>{i18n.t('insert.email')}</p>
            </div>
                
            <div style={{marginTop: '25px', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             
             <div style={{ height: '45px', borderRadius: '8px', width: '250px',backgroundColor: _itemBackgroundColor }}>
             <input 
             style={{ border: 'none', borderRadius: '8px', fontWeight: 'normal', color: _mainTextColor, height: '43px', width: '250px',
              outlineWidth: '0',backgroundColor: _itemBackgroundColor, fontSize: '14px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="email" 
             name="email"
             value={this.state.email}
             placeholder={i18n.t('Email')}
             onChange={this.insertData}
             />
             </div>
             
         </div>
            
               
                    <div  style={{marginTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.confirm}
            >
                    {i18n.t('confirm')}</p>
                    </div>

                   

                    <br />
                    <br />
                    <br />
              
            </div>
        )
    }
}

export default Email
