import React, { Component } from 'react'
import ThemeManager from './ThemeManager';

export class BookingHour extends Component {

    constructor(props){
        super(props);
        let themeManager = ThemeManager.getInstance();
        var _itemBackgroundColor = themeManager.getItemBackgroundColor();
        var _selectionColor = themeManager.getSelectionColor();
        var color = _itemBackgroundColor;
        if(props.selected){
            color =  _selectionColor ;
        }
        this.state = { hour: props.hour, itemColor: color, selected: props.selected};
    }



    static getDerivedStateFromProps(props, current_state) {
        let themeManager = ThemeManager.getInstance();
        var _itemBackgroundColor = themeManager.getItemBackgroundColor();
        var _selectionColor = themeManager.getSelectionColor();
        if(current_state.hour !== props.hour){
            return {
                hour: props.hour,
                selected: false,
                itemColor: _itemBackgroundColor
              }
        }
         if (current_state.selected !== props.selected) {   
            if(props.selected){
                return {
                    selected: true,                        
                    itemColor: _selectionColor 
                }
            }else if(!props.selected){
                return {
                    selected: false,                        
                    itemColor: _itemBackgroundColor 
                }
            } 
        }
        
        
        return null;
    }

    onItemHover = (e) => {

        if(!this.state.selected){
            let themeManager = ThemeManager.getInstance();
            var _itemBackgroundColor = themeManager.getItemBackgroundColor();

            this.setState({ itemColor: _itemBackgroundColor+'BA'});
        }

    }

    onItemLeave = (e) => {

        if(!this.state.selected){
            let themeManager = ThemeManager.getInstance();
            var _itemBackgroundColor = themeManager.getItemBackgroundColor();

            this.setState({ itemColor: _itemBackgroundColor});
        }

    }

    onItemClick = (e) => {
        if(this.state.selected){
           //this.setState({ itemColor: 'rgb(30,30,30)', selected: false});
            this.props.selectThis(this.props.slot,this.props.index,false);
        }else{
            //this.setState({ itemColor: 'rgb(255,194,0)', selected: true});
            this.props.selectThis(this.props.slot,this.props.index,true);
        }
    }

    render() {

        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemColor, borderRadius: '5px', color: _mainTextColor, textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'bold', cursor: 'pointer', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
                onMouseOver={this.onItemHover} 
                onMouseLeave={this.onItemLeave}
                onClick={this.onItemClick} >
                    {this.state.hour}
                    </div>
            </div>
        )
    }
}

export default BookingHour
