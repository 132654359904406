import React, { Component } from 'react'
import BookingDetailsSingle from './BookingDetailsSingle';
import BookingDetailsTable from './BookingDetailsTable';

export class BookingInfo extends Component {


    constructor(props){
        super(props);
        this.state = { info: props.info, bookings: props.bookings };
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.bookings !== props.bookings || current_state.info !== props.info ){

            return {
                info: props.info,
                bookings: props.bookings    
               }
        }        
        return null;
    }


    render() {
        return (
            <div>
                { this.state.info.booking_type == 'Tavoli' && 

                <BookingDetailsTable  info={this.state.info} bookings={this.state.bookings} />

                }

                { this.state.info.booking_type != 'Tavoli' && 

                <BookingDetailsSingle info={this.state.info} bookings={this.state.bookings} />
            
                }

            </div>
        )
    }
}

export default BookingInfo
