import React, { Component } from 'react'
import EventInfo from './EventInfo';
import EventList from './EventList';

export class EventManager extends Component {


    constructor(props){
        super(props);
        this.state = { events: props.events, prinfo: props.prinfo, showIndex: null  };
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.events !== props.events || current_state.prinfo !== props.prinfo){
            return {
                events: props.events,
                prinfo: props.prinfo          
               }
        }        
        return null;
    }

    goBack = () => {
        this.setState({ showIndex: null });
    }


    showEvent = (index) => {
        this.setState({ showIndex: index });
    }

    render() {
        return (
            <div>
                
            {this.state.showIndex === null && 
            <EventList events={this.state.events} select={this.showEvent} />
            }

            {this.state.showIndex !== null && 
            <EventInfo prinfo={this.state.prinfo} goBack={this.goBack} info={this.state.events[this.state.showIndex]}  />
            }

            </div>
        )
    }
}

export default EventManager
