import React, { Component } from 'react'
import BookingHours from './BookingHours';
import './App.css';
import BookingOptions from './BookingOptions';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';

export class BookingTable extends Component {


    constructor(props){
        super(props);
        var guests = 0;
        var comments = '';
        var hour = '';
        var showGuests = false;
        var showCovid = false;

        var maxGuests = 50;
        if(this.props.maxGuests !== undefined){
            maxGuests = this.props.maxGuests;
        }

        if(maxGuests == '1'){
            guests = 1;
           
        }

        var mobileSize = '12px';

        

        
        this.state = { guests: guests, 
                    commentsValue: comments, 
                    commentsPrivateValue: '',
                    selectedHour: hour, 
                    covid: false, 
                    showGuests: showGuests, 
                    showCovid: showCovid, 
                    showBook: false, 
                    showConfirm: false,
                    showOptions: false,
                    eventInfo: props.info,
                    canCancel: true,
                    optionStatus: '',
                    optionRecap: [],
                    maxGuests: maxGuests,
                    mobileValue: '', 
                    showMenu: false, 
                    numberSize: mobileSize, 
                    openPrefix: false, 
                    prefixValue: '39', 
                    canEdit: true, 
                    prefixDisplay: '🇮🇹 +39',
                    openConfirm: false,
                    isProcessing: false
                };
       
    }


    optionStatus = (status) => {
 
        if(status){
            this.setState({ showBook: true});  
        }else{
            this.setState({ showBook: false});  
        }

    }

    selectOption = (options, recapOptions) => {
      
        
        this.setState({ optionStatus: options, optionRecap: recapOptions});  
        
    }


    addGuest = (e) => {
        if(this.state.guests<this.state.maxGuests){
            this.setState({ guests: this.state.guests+1});
            this.showNextSection(this.state.guests+1);
        }
    }

    removeGuest = (e) => {

        if(this.state.guests>0){
            this.setState({ guests: this.state.guests-1});
            this.showNextSection(this.state.guests-1);
        }
       
    }

    checkboxAction = (e) => {

        if(!this.state.covid){
            this.setState({ showBook: true, covid: true}); 
        }else{
            this.setState({ showBook: false, covid: false});  
        }
    }

    showNextSection = (guests) => {
        if(guests>0){
            if(this.state.eventInfo.options!=null && this.state.eventInfo.options.length>0){
                this.setState({ showOptions: true});  
            }else{
                this.setState({ showBook: true});  
            }
                    
        }else{
            this.setState({ showBook: false, showOptions: false});  
        }
    }

    hourSelected = (hour) => {
        if(hour===''){
            this.setState({ showRecap: false, showSelection: true, showGuests: false, showOptions: false, showCovid: false, showBook: false, selectedHour: ''});
        }else{
            if(this.state.guests>0){            
                if(this.state.covid){
                    this.setState({ showGuests: true, showCovid: true, showBook: true, selectedHour: hour});
                }else{
                    this.setState({ showGuests: true, showCovid: true, showOptions: true, selectedHour: hour});
                }
            }else{
                this.setState({ showGuests: true, selectedHour: hour});
            }        
        }
    }

    changeComment = (e) => {
        this.setState({ commentsValue: e.target.value});
    }

    changePrivateComment = (e) => {
        this.setState({ commentsPrivateValue: e.target.value});
    }


    confirmBook = (e) => {
        
        if(this.state.guests > 0 && this.state.selectedHour !== ''){
          this.setState({ openConfirm: true });
        }
    }

    cancel = (e) => {
        this.props.cancel();
    }


    toggle = () => {
        this.setState({ openPrefix: false, openConfirm: false })
    }
       
    showPrefixSelection = (e) => {
        if(this.state.canEdit){ 
            this.setState({ openPrefix: true});
        }
    }

    selectPrefix = (e) => {
        
            this.setState({ prefixDisplay: e.target.innerHTML, prefixValue: e.target.id});
            this.toggle();
        
    }

    insertNumber = (e) => {

        var insert = String(e.target.value);
        
        if(!isNaN(insert)){
            this.setState({ mobileValue: insert});
        }

        if(e.target.value.length>0){
            this.setState({ numberSize: '14px'});
        }else{
            this.setState({ numberSize: '12px'});
        }

        if(e.target.value.length>7){
            this.setState({ showConfirm: true})
        }else{
            this.setState({ showConfirm: false})
        }

    }


    sendBooking = () => {
       this.props.bookNow(this.state.prefixValue, this.state.mobileValue, this.state.guests, this.state.selectedHour, this.state.commentsValue, this.state.optionStatus);
    }


    render() {


        return (
            <div>
                <div style={{ marginTop: '40px', color: 'white', fontSize: '15px', fontWeight: 'normal'}}>Nuova prenotazione</div>
                <div style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style={{ width: '70%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                
                <BookingHours hours={this.state.eventInfo.hours} selectHour={this.hourSelected} />

                { this.state.eventInfo.hours.length === 0 && 
                    <div> 
                    
                    <div style={{ marginTop: '25px', color: 'white', fontSize: '15px', fontWeight: 'normal'}}>attualmente non è possibile prenotare</div>
                    <div style={{ marginTop: '5px', color: 'white', fontSize: '25px', fontWeight: 'bold'}}>SOLD OUT</div>
                    

                    </div> 
                }

                </div>
                </div>
                { (this.state.showGuests && this.state.maxGuests>1) &&
                <div><div style={{ marginTop: '50px', color: 'white', fontSize: '15px', fontWeight: 'normal'}}>Quanti ospiti?</div>
                <div style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style={{ color: 'white', fontSize: '28px', cursor: 'pointer'}} onClick={this.removeGuest}>-</div>
                <div style={{ marginLeft: '20px', marginRight: '20px', color: 'white', fontSize: '30px'}}>{this.state.guests}</div>
                <div style={{ color: 'white', fontSize: '28px', cursor: 'pointer'}} onClick={this.addGuest}>+</div>
                </div></div>
                }

                
                { this.state.showCovid &&
                <div>
                <div style={{ marginTop: '25px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                
                </div>

                
                </div>
                }


                { this.state.showOptions && 

                <BookingOptions guests={this.state.guests} status={this.optionStatus} select={this.selectOption} options={this.state.eventInfo.options} />

                }


            { this.state.showBook &&
            <div>

<div  style={{marginTop: '50px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <input 
                style={{ border: 'none', color: 'white', fontWeight: 'normal', height: '40px', width: '250px', borderRadius: '8px', 
                backgroundColor: 'rgb(30,30,30)', border: 'none', outlineWidth: '0', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}} type="text"  
                name="comments"
                value={this.state.commentsValue}
                placeholder="note pubbliche" 
                onChange={this.changeComment}
                />
               </div>


                
               <div style={{marginTop: '40px'}}>
            <p style={{color: 'white', fontSize: '13px'}}>inserisci il numero di telefono</p>
            <p style={{marginTop: '-12px', color: 'white', fontSize: '13px'}}>a cui inviare la prenotazione</p>
            </div>



            <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'rgb(30,30,30)'}}>
            <div style={{ cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', marginLeft: '0px',
             fontSize: '14px', fontWeight: 'thin'}} onClick={this.showPrefixSelection}>
            {this.state.prefixDisplay}
            </div>
            <div>
                    <input 
                style={{ paddingLeft: '30px', border: 'none', color: 'white', fontWeight: 'bold', height: '40px', width: '150px', borderRadius: '8px', 
                backgroundColor: 'rgb(30,30,30)', outlineWidth: '0', fontSize: this.state.numberSize}} type="tel"  
                name="mobile"
                value={this.state.mobileValue}
                placeholder="numero di telefono"
                onChange={this.insertNumber}
                />
                </div>
                </div>
                </div>

                { this.state.showConfirm && 
                <div  style={{marginTop: '15px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(255,194,0)', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmBook}
            >
                    Avanti</p>
                    </div>
                }


                   </div> 
            }

                    {
                        this.state.canCancel
                   ? (
                    <div onClick={this.cancel} style={{ marginTop: '50px', cursor: 'pointer', fontSize: '14px', color: 'white' }}>annulla</div>
                   )
                   : (
                     null
                   )
                    }

                    <br />
                    <br />
                    <br />

                    <Drawer
                    open={this.state.openConfirm}
                        onRequestClose={this.toggle}
                        modalElementClass="modal">
                            <div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginTop: '20px', fontSize: '14px', color: 'rgb(60,60,60)' }}>Stai inviando una prenotazione al numero 
                    <p style={{ color: 'rgb(30,30,30)', fontWeight: 'bold', fontSize: '16px'}}>+{this.state.prefixValue} {this.state.mobileValue}</p></div>
                    </div>
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: 'rgb(255,194,0)', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.sendBooking}
            >Invia</p>
                    </div>
                    <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div onClick={this.toggle} style={{cursor: 'pointer', fontSize: '14px', color: 'rgb(60,60,60)'}}>annulla</div>
                    </div>
                    </div>
                    </Drawer>
                    
                    <Drawer
                    open={this.state.openPrefix}
                        onRequestClose={this.toggle}
                        modalElementClass="modalPrefix"
                        style={{ background: 'red'}}>
                            
                            <div style={{marginLeft: '-10px'}}>
                            <div  style={{ marginTop: '0px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="39">🇮🇹 +39</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="41">🇨🇭 +41</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="49">🇩🇪 +49</p>
                    
                    </div>
                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="43">🇦🇹 +43</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="44">🇬🇧 +44</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="33">🇫🇷 +33</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="31">🇳🇱 +31</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="81">🇯🇵 +81</p>
              <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
             fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="86">🇨🇳 +86</p>
                    
                    </div>

                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="30">🇬🇷 +30</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                        fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="7">🇷🇺 +7</p>
                    <p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
                    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="34">🇪🇸 +34</p>

                    </div>


                    <div  style={{marginTop: '-25px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="32">🇧🇪 +32</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
    fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="47">🇳🇴 +47</p>
<p style={{cursor: 'pointer', width: '80px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(60,60,60)', marginLeft: '0px',
fontSize: '15px', fontWeight: 'normal'}} onClick={this.selectPrefix} id="46">🇸🇪 +46</p>

</div>

                    </div>
                    </Drawer>
            </div>
        )
    }
}

export default BookingTable
