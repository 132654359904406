import React, { Component } from 'react'
import PlaceManager from './PlaceManager';

export class PrManager extends Component {

    constructor(props){
        super(props);
        this.state = { info: [] };
    }

    componentDidMount () {

        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
            this.window.reload();
        }else{
            this.getPrInfo();
        }
       
    }

    getPrInfo = () => {


            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
              sessiontoken = '';
            }
        
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: JSON.stringify({
                        token: sessiontoken
                      })
              };
            
                  const apiUrl = 'https://getfy-business.com/pr/v1/info/';
        
            fetch(apiUrl, requestOptions)
            .then(r => r.json())
            .then(r => { 
                   this.setState({ info: r.info });          
            }).catch(e => {
            });
 
    }


    render() {
        return (
            <div>
                <PlaceManager info={this.state.info} />
            </div>
        )
    }
}

export default PrManager
