import React, { Component } from 'react'

export class PlaceList extends Component {

    constructor(props){
        super(props);
        
        this.state = { places: props.places };
    }

    static getDerivedStateFromProps(props, current_state) {
        if(current_state.places !== props.places){
            return {
                places: props.places          
               }
        }        
        return null;
    }

    selectPlace = (index) => {
        this.props.select(index);
    }

    render() {
        return (
            <div>
                <div style={{ marginTop: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>   
               
                {this.state.places.length == '0' && 
                <div style={{ color: 'white' }}>Nessun PR associato</div>
                }
               
                <div>  
                {
                    this.state.places.map((place, i) =>

                    <div  onClick={() => this.selectPlace(i)} key={i} style={{marginTop: '15px', width: '250px', backgroundColor: 'rgb(30,30,30)', borderRadius: '5px', color: 'rgb(230,230,230)', textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'normal', cursor: 'pointer', fontSize: '13px', boxShadow: 'none', outline: 'none !important'}} 
                 >
                    <div style={{ marginLeft: '15px', width: '30px'}}>

                   <p style={{fontSize: '20px'}}>🎉</p>
                 
                    </div>
                    <div style={{ width: '200px', textAlign: 'left', 
                justifyContent: 'left', alignItems: 'left', marginLeft: '15px'}}>
                    <p style={{color: 'white', fontWeight: 'bold'}}>{place.name}</p>
                        <p style={{marginTop: '-10px'}}> {place.prname} </p>
                  
                    </div>
                    </div>
                   
                
                )

            }

            </div>
            </div>

            </div>
        )
    }
}

export default PlaceList
