import React, { Component } from 'react'
import Login from './Login/Login';
import PersonalData from './PersonalInfo/PersonalData';
import UserProfile from './UserProfile';

export class UserManager extends Component {

    constructor(props){
        super(props);
        
       

        this.state = { userInfo: null, isReady: false, userProfileOpened: false };
        
    }

    componentDidMount () {

        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
            this.setState({ isReady: true });
        }else{
            this.checkStatus();
        }
       
    }

    checkStatus = () => {


            var sessiontoken = localStorage.getItem('session_token');
            if(sessiontoken === null){
              sessiontoken = '';
            }
        
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: JSON.stringify({
                        token: sessiontoken
                      })
              };
            
                  const apiUrl = 'https://getfy-business.com/pr/v1/login/status';
        
            fetch(apiUrl, requestOptions)
            .then(r => r.json())
            .then(r => { 
                console.log(r);
                this.manageUserStatus(r.user);                
            }).catch(e => {
                localStorage.removeItem('session_token');
                window.location.reload();
            });
 
    }

    manageUserStatus = (userinfo) => {
        if(userinfo.birth === 'ok' && userinfo.sex === 'ok' && userinfo.info.email != ''){
            this.setState({ userInfo: userinfo, isReady: true });
            this.props.userReady();
        }else{
            this.setState({ userInfo: userinfo, isReady: true });
        }
    }

    openUserProfile = (open) => {
        this.props.open(open);
        this.setState({ userProfileOpened: open });
      }

    render() {
        return (
            <div>
                { this.state.isReady &&
                <div>
                { this.state.userInfo === null && <Login /> }
               
                {
                   this.state.userInfo !== null && (this.state.userInfo.birth !== 'ok' || this.state.userInfo.sex !== 'ok' || this.state.userInfo.info.email == '' )
                   ? (
                       <PersonalData user={this.state.userInfo} /> 
                   )
                   : (
                       null
                   )
               }

                {
                   this.state.userInfo !== null && (this.state.userInfo.birth === 'ok' && this.state.userInfo.sex === 'ok' && this.state.userInfo.info.email != '' )
                   ? (
                    <UserProfile open={this.openUserProfile} info={this.state.userInfo.info} />
                   )
                   : (
                       null
                   )
               }
                
               </div>
                }

                </div>
        )
    }
}
//<UserProfile open={this.openUserProfile} info={this.state.userInfo} />
export default UserManager
