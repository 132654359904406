import logoWhite from './logo-white.svg';
import { Component } from 'react';
import './App.css';
import UserManager from './UserManager';
import PrManager from './PrManager';

class App extends Component {


  constructor(props){
    super(props);
    
    this.state = { isReady: false, userProfileOpened: false };
  }

  userReady = () => {
    this.setState({ isReady: true });
  }

  openUserProfile = (open) => {
    this.setState({ userProfileOpened: open });
  }

  render(){
    

    return (
     
    <div className="App">
    <img alt="Getfy" style={{marginTop: '40px', height: '15px'}} src={logoWhite} />


    <UserManager open={this.openUserProfile} userReady={this.userReady} />

    <br /><br />

      { (this.state.isReady && !this.state.userProfileOpened) &&
        <PrManager />
      }


    
<br />
<br />
    </div>

    )
  };
}

export default App;
