import React, { Component } from 'react'
import ThemeManager from '../ThemeManager';

export class Sex extends Component {

    constructor(props){
        super(props);
        let themeManager = ThemeManager.getInstance();
        var _itemBackgroundColor = themeManager.getItemBackgroundColor();
        
        this.state = { userInfo: props.user, itemsColor: [_itemBackgroundColor, _itemBackgroundColor, _itemBackgroundColor], selected: 0};
       
    }

    confirm = (e) => {

       var sex = this.state.selected;

        var sessiontoken = localStorage.getItem('session_token');
        if(sessiontoken === null){
          sessiontoken = '';
        }
    
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
                    sex: sex,
                    token: sessiontoken
                  })
          };
        
              const apiUrl = 'https://getfy-business.com/pr/v1/user/set/sex';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => { 
            window.location.reload();
        }).catch(e => {
            window.location.reload();
        });
    }


    onItemHover = (e) => {
        if(this.state.selected === 0){
            let themeManager = ThemeManager.getInstance();
            var _itemBackgroundColor = themeManager.getItemBackgroundColor();

            var itemsColor = this.state.itemsColor;
            itemsColor[e.target.id] = _itemBackgroundColor+'BA';
            this.setState({ itemsColor: itemsColor});
        }

    }

    onItemLeave = (e) => {
        if(this.state.selected === 0){
            let themeManager = ThemeManager.getInstance();
            var _itemBackgroundColor = themeManager.getItemBackgroundColor();

            var itemsColor = this.state.itemsColor;
            itemsColor[e.target.id] = _itemBackgroundColor;
            this.setState({ itemsColor: itemsColor});
        }

    }

    onItemClick = (e) => {
        let themeManager = ThemeManager.getInstance();
            var _itemBackgroundColor = themeManager.getItemBackgroundColor();
            var _selectionColor = themeManager.getSelectionColor();

        if(this.state.selected !== 0){           
            var itemsColor = [_itemBackgroundColor, _itemBackgroundColor, _itemBackgroundColor];
            itemsColor[e.target.id] = _selectionColor;
            this.setState({ itemsColor: itemsColor, selected: e.target.id});
        }else{
            var itemsColor = this.state.itemsColor;
            itemsColor[e.target.id] = _selectionColor;
            this.setState({ itemsColor: itemsColor, selected: e.target.id});
        }
    }

    render() {


        let themeManager = ThemeManager.getInstance();
        const _backgroundColor = themeManager.getBackgroundColor();
        const _selectionColor = themeManager.getSelectionColor();
        const _mainTextColor = themeManager.getMainTextColor();
        const _secondaryTextColor = themeManager.getSecondaryTextColor();
        const _buttonTextColor = themeManager.getButtonTextColor();
        const _itemBackgroundColor = themeManager.getItemBackgroundColor();
        const _errorColor = themeManager.getErrorColor();

        return (
            <div>
                
             <div style={{marginTop: '80px'}}>
            <p style={{color: _mainTextColor, fontSize: '13px'}}>ci siamo quasi</p>
            </div>
                
          
            <div style={{ marginTop: '35px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
               
               <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemsColor[0], borderRadius: '8px', color: _mainTextColor, textAlign: 'center', 
               justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'bold', cursor: 'pointer', fontSize: '13px'}} 
               id="0" 
               onMouseOver={this.onItemHover} 
               onMouseLeave={this.onItemLeave}
               onClick={this.onItemClick} >
                   uomo
               </div>

              
               
                <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemsColor[1], borderRadius: '8px', color: _mainTextColor, textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'bold', cursor: 'pointer', fontSize: '13px'}} 
                id="1" 
                onMouseOver={this.onItemHover} 
                onMouseLeave={this.onItemLeave}
                onClick={this.onItemClick} >
                    donna
                </div>

                
                <div style={{marginRight: '7.5px', marginLeft: '7.5px', width: '60px', height: '60px', backgroundColor: this.state.itemsColor[2], borderRadius: '8px', color: _mainTextColor, textAlign: 'center', 
                justifyContent: 'center', alignItems: 'center', display: 'flex', fontWeight: 'bold', cursor: 'pointer', fontSize: '13px'}} 
                id="2" 
                onMouseOver={this.onItemHover} 
                onMouseLeave={this.onItemLeave}
                onClick={this.onItemClick} >
                    altro
                </div>

                </div>
          
            
                <div  style={{marginTop: '30px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '250px', borderRadius: '8px', backgroundColor: _selectionColor, fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: _buttonTextColor}}
            onClick={this.confirm}
            >
                    Conferma</p>
                    </div>
                   

                    <br />
                    <br />
                    <br />
              
            </div>
        )
    }
}

export default Sex
